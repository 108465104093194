import React from 'react';
import styles from './Footer.module.scss';

const Footer = () => {

    return (
        <div className={styles.Footer}>
            <p>Hr bee Jobs është pjesë e Hr Bee LLC. Të gjitha të drejtat janë të rezervuara © 2019-2023</p>
            <p>Kontakti: WhatsApp/Viber +383 48 51 99 99 <span className='mx-2'>|</span> jobs@hrbee.ai</p>
        </div>
    );
};

export default Footer;