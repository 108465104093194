import * as c from "../../constants";

const initialState = () => {
    return {
        jobs: [],
        category_jobs: [],
        countries_jobs: [],
        cities_jobs: [],
        job: null,
        filters: false,
    }
}

export const jobsReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.GET_JOBS:
            return { ...state, jobs: action.payload }
        case c.GET_JOB:
            return { ...state, job: action.payload }
        case c.GET_CATEGORY_JOBS:
            return { ...state, category_jobs: action.payload }
        case c.GET_COUNTRIES_JOBS:
            return { ...state, countries_jobs: action.payload }
        case c.GET_CITIES_JOBS:
            return { ...state, cities_jobs: action.payload }
        case c.SET_OPEN_FILTERS:
            return { ...state, filters: action.payload }
        default:
            return state
    }
}
