import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { setCountries, setLanguages, setLanguagesLevels, setComputerSkills, setSkillsLevels, setDrivinigLicenses, setBusinessCategories } from './redux/actions/app';
import PublicRoute from './auth-routes/PublicRoute';
import JobSeekerRoute from './auth-routes/JobSeekerRoute';

import Home from './pages/home/Home';
import JobPage from './pages/Job/JobPage';
import Vacancy from './pages/vacancy/Vacancy';
import Auth from './pages/auth/Auth';
import JobSeekerProfile from './pages/jobseeker/profile/Profile';
import Forget from './pages/auth/Forget';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCountries());
    dispatch(setLanguages());
    dispatch(setLanguagesLevels());
    dispatch(setComputerSkills());
    dispatch(setSkillsLevels());
    dispatch(setDrivinigLicenses());
    dispatch(setBusinessCategories());
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<PublicRoute><Home /></PublicRoute>} />
        <Route path="/job/:id" exact element={<PublicRoute><JobPage /></PublicRoute>} />
        <Route path="/publish-vacancy" exact element={<PublicRoute><Vacancy /></PublicRoute>} />
        <Route path="/login" exact element={<PublicRoute><Auth /></PublicRoute>} />
        <Route path="/auth/:token/:email" exact element={<PublicRoute><Forget /></PublicRoute>} />

        {/* Jobseeker Routes */}
        <Route path="/jobseeker/profile" exact element={<JobSeekerRoute><JobSeekerProfile /></JobSeekerRoute>} />
      </Routes>
    </Router>
  );
}

export default App;
