import * as c from "../constants";

const initialState = () => {
    return {
        partners: [],
        countries: [],
        languages: [],
        languages_levels: [],
        computer_skills: [],
        skills_levels: [],
        driving_licenses: [],
        business_categories: [],
        rightDrawer: false
    }
}

export const appReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.GET_PARTNERS:
            return { ...state, partners: action.payload }
        case c.SET_COUNTRIES:
            return { ...state, countries: action.payload }
        case c.SET_LANGUAGES:
            return { ...state, languages: action.payload }
        case c.SET_LANGUAGES_LEVELS:
            return { ...state, languages_levels: action.payload }
        case c.SET_COMPUTER_SKILLS:
            return { ...state, computer_skills: action.payload }
        case c.SET_SKILLS_LEVELS:
            return { ...state, skills_levels: action.payload }
        case c.SET_DRIVING_LICENSES: 
            return { ...state, driving_licenses: action.payload }
        case c.TOGGLE_DRAWER:
            return { ...state, rightDrawer: action.payload }
        case c.SET_BUSINESS_CATEGORIES:
            return { ...state, business_categories: action.payload }
        default:
            return state
    }
}
