import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import styles from "./Forms.module.scss";
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import { getJobSeekerProfile, storeLicense, updateLicense } from '../../../../redux/actions/jobseeker/profile';
import { toggleDrawer } from '../../../../redux/actions/app';

const LicenseForm = (props) => {
    const { onFinish, selectedData } = props;
    const dispatch = useDispatch();

    const drivingLicenses = useSelector((state) => state.app.driving_licenses);

    const [category, setCategory] = useState(selectedData ? selectedData.driving_category : "");

    const submitData = (e) => {
        e.preventDefault();
        let data = {
            "driving_category": category,
        };
        if(selectedData){
            data = {...data, "id": selectedData.seq_id}
        }
        dispatch(selectedData ? updateLicense(data) : storeLicense(data)).then(res => {
            dispatch(getJobSeekerProfile()).then(res => {
                toast.success(selectedData ? "Licensa u ndryshua me sukses" : "Licensa u shtua me sukses");
                dispatch(toggleDrawer(false));
                onFinish();
            }).catch(err => {
                toast.error("Diçka shkoi keq. Ju lutem rifreskoni faqen!");
            });
        }).catch(err => {
            toast.error("Diçka shkoi keq. Ju lutem provoni përsëri!");
        });
    }

    return (
        <div>
            <form onSubmit={submitData}>
                <FormControl variant="outlined" fullWidth={true} size="small" classes="my-2">
                    <InputLabel id="demo-simple-select-outlined-label">Licensa</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Licensa"
                        name="driving_category"
                        value={category}
                        onChange={e => setCategory(e.target.value)}
                    >
                        {drivingLicenses && drivingLicenses.map(license => (
                            <MenuItem key={license.driving_category} value={license.driving_category}>
                                {license.driving_category}
                            </MenuItem>     
                        ))}                     
                    </Select>
                </FormControl>
                <div className='mt-4 text-center'>
                    <button className={styles.SubmitButton}>Ruaj</button>
                </div>
            </form>
        </div>
    );
};

export default LicenseForm;