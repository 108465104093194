import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { TextField, Autocomplete } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { login, register, resetPassword } from '../../redux/actions/auth';
import { toast } from "react-toastify";
import authImg from "../../assets/images/authpage.png";
import styles from './Auth.module.scss';

const Auth = () => {
    const isMobile = window.innerWidth < 500;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user);
    const countries = useSelector((state) => state.app.countries);
    const [cities, setCities] = useState([]);

    const [form, setForm] = useState("login");

    const [errors, setErrors] = useState(false);
    const [loadingLogin, setLoadingLogin] = useState(false);
    const [loadingRegister, setLoadingRegister] = useState(false);
    const [emailLogin, setEmailLogin] = useState();
    const [passwordLogin, setPasswordLogin] = useState();

    const [name, setName] = useState();
    const [surname, setSurname] = useState();
    const [phone, setPhone] = useState();
    const [country, setCountry] = useState();
    const [city, setCity] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();

    const [loadingResetPassword, setLoadingResetPassword] = useState(false);
    const [resetEmail, setResetEmail] = useState();

    const onLoginFormHandler = (e) => {
        e.preventDefault();
        setLoadingLogin(true);   
        dispatch(login(emailLogin, passwordLogin)).then(res => {
            setLoadingLogin(false);
        }).catch(err => {
            setLoadingLogin(false);
            if(err.data.message === "The user credentials were incorrect."){
                toast.error("Kredenciale të gabuara!");
            }else{
                toast.error("Diçka shkoi keq. Ju lutem provoni përsëri!");
            }
        });     
    };

    const onRegisterFormHandler = (e) => {
        e.preventDefault();
        if(password === confirmPassword){
            setErrors(false);
            setLoadingRegister(true);   
            const cityFound = cities.find(c => c.name === city);
            const countryFound = countries.find(c => c.country_name === country);
            const data = {
                "name": name,
                "surname": surname,
                "country_name": country,
                "country_value": countryFound.country_iso,
                "city_name": city,
                "city_value": cityFound.value,
                "email": email,
                "password": password,
                "password_confirmation": confirmPassword,
                "phone": phone,
                'user_type': 'job_seeker',
            };
            dispatch(register(data)).then(res => {
                toast.success("Ju jeni regjistruar në HrBee!");
                dispatch(login(email, password)).then(res => {
                    setLoadingRegister(false);
                    navigate("/jobseeker/profile");
                });
            }).catch(err => {
                if(err.data.message === "email_alredy_exist"){
                    toast.error("Ky email egziston. Ju lutem provoni të kyqeni!");
                }else{
                    toast.error("Diçka shkoi keq. Ju lutem provoni përsëri!");
                }
                setLoadingRegister(false);
            });   
        }else{
            setErrors(true);
        }
    };

    const onResetPassword = (e) => {
        e.preventDefault();
        setLoadingResetPassword(true);
        const data = {
            "email": resetEmail,
            "website": "jobs"
        };
        dispatch(resetPassword(data)).then(res => {
            toast.success("Ju do të pranoni një email për rivendsje të fjalëkalimit");
            setLoadingResetPassword(false);
        }).catch(err => {
            toast.error("Diçka shkoi keq. Ju lutem provoni përsëri!");
            setLoadingResetPassword(false);
        });
    }

    useEffect(() => {
        if(user && user.user_type === "job_seeker"){
            navigate("/");
        }
    }, [user, navigate]);

    useEffect(() => {
        if(country){
            countries.forEach(element => {
                if(element.country_iso === country){
                    setCities(element.cities);
                }
            });
        }
    }, [country, countries]);

    return(
        <div className='container mb-5 text-center'>
            <img alt="Auth" style={{ marginTop: '50px' }} src={authImg} width={isMobile ? "100%" : "600px"} />

            <div className={styles.AuthPageWrapper}>
                <div>
                    <p className={styles.Description}>Duke dixhitalizuar CV-in tuaj, fillimisht krijoni një standard për profilin tuaj,</p> 
                    <p className={styles.Description}>i cili është i rëndësishëm për procesin e mëtejshëm të aplikimit për punë.</p>
                    <p style={{ marginTop: '15px'}} className={styles.Description}>Ju duhet vetëm një herë të futni të dhënat tuaja në sistem</p> 
                    <p className={styles.Description}>dhe më pas të aplikoni për punë të ndryshme me vetëm një klikim.</p>
                    <div>
                        {form === "login" && (
                            <button className={styles.Button} onClick={() => setForm("register")}>
                                Regjistrohu
                            </button>
                        )}
                    </div>
                </div>
                {!isMobile && (
                    <div className={styles.Border}></div>
                )}
                <div className='text-center'>
                    {form === "login" && (
                        <form onSubmit={onLoginFormHandler}>
                            <h4 className={styles.FormTitle}>Kyqu</h4>
                            <div>
                                <TextField 
                                    id="outlined-basic" 
                                    size="small" 
                                    type="email"
                                    variant="outlined" 
                                    onChange={e => setEmailLogin(e.target.value)} 
                                    className="my-2" 
                                    value={emailLogin} 
                                    label="E-mail"
                                    style={{width: "300px"}}
                                    required
                                />
                            </div>
                            <div>
                                <TextField 
                                    id="outlined-basic" 
                                    size="small" 
                                    type="password"
                                    variant="outlined" 
                                    onChange={e => setPasswordLogin(e.target.value)} 
                                    className="my-2" 
                                    value={passwordLogin} 
                                    label="Fjalëkalimi"
                                    style={{width: "300px"}}
                                    required
                                />
                            </div>
                            <button className={styles.Button} type="submit" disabled={loadingLogin}>
                                Kyqu
                            </button>
                            <div className={styles.FooterCardLogin}>
                                <p onClick={() => setForm('reset-password')}>Kam harruar <span>fjalëkalimin!</span></p>
                            </div>
                        </form>
                    )}
                    {form === "register" && (
                        <form onSubmit={onRegisterFormHandler}>
                            <h4 className={styles.FormTitle}>Regjistrohu</h4>
                            <div>
                                <TextField 
                                    id="outlined-basic" 
                                    size="small"
                                    variant="outlined" 
                                    onChange={e => setName(e.target.value)} 
                                    className="mb-2" 
                                    value={name} 
                                    label="Emri" 
                                    style={{width: "300px"}}
                                    required
                                />
                            </div>
                            <div>
                                <TextField 
                                    id="outlined-basic" 
                                    size="small" 
                                    variant="outlined" 
                                    onChange={e => setSurname(e.target.value)} 
                                    className="my-2" 
                                    value={surname} 
                                    label="Mbiemri" 
                                    style={{width: "300px"}}
                                    required
                                />
                            </div>
                            <div>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    size='small'
                                    options={countries.map((option) => option.country_name)}
                                    value={country}
                                    onChange={(e, newValue) => setCountry(newValue)}
                                    renderInput={(params) => <TextField {...params} className="my-2" style={{width: "300px"}} label="Shteti" required />}
                                />
                            </div>
                            <div>
                                <Autocomplete
                                    disablePortal
                                    disabled={!country}
                                    id="combo-box-demo"
                                    size='small'
                                    options={cities.map((option) => option.name)}
                                    value={city}
                                    onChange={(e, newValue) => setCity(newValue)}
                                    renderInput={(params) => <TextField {...params} style={{width: "300px"}} className="my-2" label="Qyteti" required />}
                                />
                            </div>
                            <div>
                                <TextField 
                                    id="outlined-basic" 
                                    size="small"
                                    variant="outlined" 
                                    onChange={e => setPhone(e.target.value)} 
                                    className="my-2" 
                                    value={phone} 
                                    label="Numri kontaktues" 
                                    style={{width: "300px"}}
                                    required
                                />
                            </div>
                            <div>
                                <TextField 
                                    id="outlined-basic" 
                                    size="small" 
                                    type="email"
                                    variant="outlined" 
                                    onChange={e => setEmail(e.target.value)} 
                                    className="my-2" 
                                    value={email} 
                                    label="E-mail" 
                                    style={{width: "300px"}}
                                    required
                                />
                            </div>
                            <div>
                                <TextField 
                                    id="outlined-basic" 
                                    size="small" 
                                    type="password"
                                    variant="outlined" 
                                    onChange={e => setPassword(e.target.value)} 
                                    className="my-2" 
                                    value={password} 
                                    label="Fjalëkalimi" 
                                    style={{width: "300px"}}
                                    required
                                />
                            </div>
                            <div>
                                <TextField
                                    size="small"
                                    type="password"
                                    variant="outlined"
                                    style={{width: "300px"}}
                                    label="Konfirmo fjalëkalimin"
                                    name="confirmPassword"
                                    className="my-2" 
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                    error={errors && password !== confirmPassword}
                                    helperText={(errors && password !== confirmPassword && "Fjalëkalimet nuk përputhen")}
                                />
                            </div>
                            <button className={styles.Button} type="submit" disabled={loadingRegister}>
                                Regjistrohu
                            </button>
                            <div className={styles.FooterCardLogin}>
                                <p onClick={() => setForm("login")}>Kam llogari dhe dua të <span>kyçem!</span></p>
                            </div>
                        </form>
                    )}
                    {form === "reset-password" && (
                        <form onSubmit={onResetPassword}>
                            <h4 className={styles.FormTitle}>Rivendos fjalëkalimin!</h4>
                            <div>
                                <TextField 
                                    id="outlined-basic" 
                                    size="small" 
                                    type="email"
                                    variant="outlined" 
                                    onChange={e => setResetEmail(e.target.value)} 
                                    className="my-2" 
                                    value={resetEmail} 
                                    label="E-mail"
                                    style={{width: "300px"}}
                                    required
                                />
                            </div>
                            <button className={styles.Button} type="submit" disabled={loadingResetPassword}>
                                Rivendos
                            </button>
                            <div className={styles.FooterCardLogin}>
                                <p onClick={() => setForm("login")}>Kam llogari dhe dua të <span>kyçem!</span></p>
                            </div>
                        </form>
                    )}
                </div>
            </div>

        </div>
    );
};

export default Auth;