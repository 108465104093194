import React, { useState, useCallback } from 'react';
import { TextField, FormControlLabel, Checkbox } from '@mui/material';
import styles from "./Forms.module.scss";
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import { getJobSeekerProfile, storeEducation, updateEducation } from '../../../../redux/actions/jobseeker/profile';
import { toggleDrawer } from '../../../../redux/actions/app';

const EducationForm = (props) => {
    const { onFinish, selectedData } = props;
    const dispatch = useDispatch();

    const [school, setSchool] = useState(selectedData ? selectedData.school : "");
    const [degree, setDegree] = useState(selectedData ? selectedData.degree : "");
    const [fieldStudy, setFieldStudy] = useState(selectedData ? selectedData.field_of_study : "");
    const [joinDate, setJoinDate] = useState(selectedData ? selectedData.from_date : "");
    const [leaveDate, setLeaveDate] = useState(selectedData ? selectedData.to_date : "");
    const [stillWorking, setStillWorking] = useState(selectedData ? selectedData.ongoing : "");
    const [country, setCountry] = useState(selectedData ? selectedData.country : "");
    const [city, setCity] = useState(selectedData ? selectedData.city : "");
    const [file, setFile] = useState();

    const submitData = (e) => {
        e.preventDefault();
        const data = new FormData()
        data.append('school', school);
        data.append('degree', degree);
        data.append('field_of_study', fieldStudy);
        data.append('country', country);
        data.append('city', city);
        data.append('from_date', joinDate);
        data.append('to_date', leaveDate);
        data.append('ongoing', stillWorking ? parseInt(1) : parseInt(0));
        if(file){
            data.append('file', file);
        }
        if(selectedData){
            data.append('id', selectedData.seq_id);
        }
        dispatch(selectedData ? updateEducation(data) : storeEducation(data)).then(res => {
            dispatch(getJobSeekerProfile()).then(res => {
                toast.success(selectedData ? "Edukimi u ndryshua me sukses" : "Edukimi u shtua me sukses");
                dispatch(toggleDrawer(false));
                onFinish();
            }).catch(err => {
                toast.error("Diçka shkoi keq. Ju lutem rifreskoni faqen!");
            });
        }).catch(err => {
            toast.error("Diçka shkoi keq. Ju lutem provoni përsëri!");
        });
    }

    return (
        <div>
            <form onSubmit={submitData}>
                <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    label="Shkolla"
                    name="school"
                    className='my-2'
                    required
                    value={school}
                    onChange={e => setSchool( e.target.value)}
                />
                <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    label="Niveli, BA, MA"
                    name="degree"
                    required
                    className='my-2'
                    value={degree}
                    onChange={e => setDegree(e.target.value)}
                />
                <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    label="Drejtimi"
                    required
                    name="field_of_study"
                    value={fieldStudy}
                    className="my-2"
                    onChange={e => setFieldStudy(e.target.value)}
                />
                <TextField
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    type="date"
                    variant="outlined"
                    fullWidth
                    label="Data e fillimit"
                    name="join_date"
                    className="my-2"
                    required
                    value={joinDate}
                    onChange={e => setJoinDate(e.target.value)}
                />
                <TextField
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    type="date"
                    variant="outlined"
                    fullWidth
                    label="Data e mbarimit"
                    name="leave_date"
                    className="my-2"
                    required={!stillWorking}
                    value={leaveDate}
                    onChange={e => setLeaveDate(e.target.value)}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={stillWorking}
                            onChange={e => setStillWorking(e.target.checked)}
                            name="still_working"
                            color="primary"
                            size="small"
                        />
                    }
                    label="Prezent"
                />
                <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    label="Shteti"
                    name="country"
                    value={country}
                    className="my-2"
                    required
                    onChange={e => setCountry(e.target.value)}
                />
                <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    label="Qyteti"
                    name="city"
                    className="my-2"
                    required
                    value={city}
                    onChange={e => setCity(e.target.value)}
                />
                <TextField
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    variant="outlined"
                    fullWidth
                    label="Dokument"
                    name="file"
                    type="file"
                    className="my-2"
                    onChange={(e) => setFile(e.target.files[0])}
                />
                <div className='mt-4 text-center'>
                    <button className={styles.SubmitButton}>Ruaj</button>
                </div>
            </form>
        </div>
    );
};

export default EducationForm;