import API from '../../api';
import * as c from "../../constants";

// Get jobseeker profile 
export const getJobSeekerProfile = (params) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get(`/api/jobshrbee/getJobSeekerProfile`, config)
        dispatch({ type: c.GET_JOBSEEKER_PROFILE, payload: response.data })

        return response
    } catch (error) {
        throw error
    }
}

export const uploadProfilePicture = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/api/uploadProfileImage`, data, config)

        return response
    } catch (error) {
        throw error
    }
}

export const storeExperience = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/api/jobshrbee/storeExperience`, data, config)

        return response
    } catch (error) {
        throw error
    }
}

export const storeEducation = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/api/jobshrbee/storeEducation`, data, config)

        return response
    } catch (error) {
        throw error
    }
}

export const storeTraining = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/api/jobshrbee/storeTraining`, data, config)

        return response
    } catch (error) {
        throw error
    }
}

export const storeLanguage = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/api/jobshrbee/storeLanguage`, data, config)

        return response
    } catch (error) {
        throw error
    }
}

export const storeSkill = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/api/jobshrbee/storeSkill`, data, config)

        return response
    } catch (error) {
        throw error
    }
}

export const storeLicense = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/api/jobshrbee/storeLicense`, data, config)

        return response
    } catch (error) {
        throw error
    }
}

export const updateProfile = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/api/jobshrbee/updateProfile`, data, config);
        dispatch({ type: c.UPDATED_PROFILE, payload: response.data });

        return response
    } catch (error) {
        throw error
    }
}

export const updateSkill = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/api/jobshrbee/updateSkill`, data, config);

        return response
    } catch (error) {
        throw error
    }
}

export const updateLicense = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/api/jobshrbee/updateLicense`, data, config);

        return response
    } catch (error) {
        throw error
    }
}

export const updateLanguage = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/api/jobshrbee/updateLanguage`, data, config);

        return response
    } catch (error) {
        throw error
    }
}

export const updateTraining = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/api/jobshrbee/updateTraining`, data, config);

        return response
    } catch (error) {
        throw error
    }
}

export const updateEducation = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/api/jobshrbee/updateEducation`, data, config);

        return response
    } catch (error) {
        throw error
    }
}

export const updateExperience = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post(`/api/jobshrbee/updateExperience`, data, config);

        return response
    } catch (error) {
        throw error
    }
}

export const deleteExperience = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.delete(`/api/jobshrbee/deleteExperience/${id}`, config);

        return response
    } catch (error) {
        throw error
    }
}

export const deleteEducation = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.delete(`/api/jobshrbee/deleteEducation/${id}`, config);

        return response
    } catch (error) {
        throw error
    }
}

export const deleteTraining = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.delete(`/api/jobshrbee/deleteTraining/${id}`, config);

        return response
    } catch (error) {
        throw error
    }
}

export const deleteLanguage = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.delete(`/api/jobshrbee/deleteLanguage/${id}`, config);

        return response
    } catch (error) {
        throw error
    }
}

export const deleteSkill = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.delete(`/api/jobshrbee/deleteSkill/${id}`, config);

        return response
    } catch (error) {
        throw error
    }
}

export const deleteLicense = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.delete(`/api/jobshrbee/deleteLicense/${id}`, config);

        return response
    } catch (error) {
        throw error
    }
}
