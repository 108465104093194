import React from 'react';
import styles from './LabelValue.module.scss';
import { documentsUrl } from '../../global';

const LabelValue = (props) => {
    const { label, value, type } = props;

    return (
        <div className={styles.LabelValueWrapper}>
            <p className={styles.Label}>
                {label}
            </p>
            {type === "link" ? (
                <a className={styles.Link} href={`${documentsUrl}${value}`} target="_blank">Shiko dokumentin</a>
            ) : (
                <p className={styles.Value}>
                    {value}
                </p>
            )}
        </div>
    );
};

export default LabelValue;