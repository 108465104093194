import React, { useState } from 'react';
import { TextField, FormControlLabel, Checkbox } from '@mui/material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import styles from "./Forms.module.scss";
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import { getJobSeekerProfile, storeExperience, updateExperience } from '../../../../redux/actions/jobseeker/profile';
import { toggleDrawer } from '../../../../redux/actions/app';

const ExperienceForm = (props) => {
    const { onFinish, selectedData } = props;
    const dispatch = useDispatch();

    const [companyName, setCompanyName] = useState(selectedData ? selectedData.company_name : "");
    const [position, setPosition] = useState(selectedData ? selectedData.position : "");
    const [joinDate, setJoinDate] = useState(selectedData ? selectedData.join_date : "");
    const [leaveDate, setLeaveDate] = useState(selectedData ? selectedData.leave_date : "");
    const [stillWorking, setStillWorking] = useState(selectedData ? selectedData.still_working : "");
    const [country, setCountry] = useState(selectedData ? selectedData.country : "");
    const [city, setCity] = useState(selectedData ? selectedData.city : "");
    const [description, setDescription] = useState(selectedData ? selectedData.description : "<p>Pershkrimi ...</p>");

    const submitData = (e) => {
        e.preventDefault();
        let data = {
            "company_name": companyName,
            "position": position,
            "country": country,
            "city": city,
            "join_date": joinDate,
            "leave_date": leaveDate,
            "still_working": stillWorking,
            "description": description,
        };
        if(selectedData){
            data = {...data, "id": selectedData.seq_id}
        }
        dispatch(selectedData ? updateExperience(data) : storeExperience(data)).then(res => {
            dispatch(getJobSeekerProfile()).then(res => {
                toast.success(selectedData ? "Pervoja e punes u ndryshua me sukses" : "Pervoja e punes u shtua me sukses");
                dispatch(toggleDrawer(false));
                onFinish();
            }).catch(err => {
                toast.error("Diçka shkoi keq. Ju lutem rifreskoni faqen!");
            });
        }).catch(err => {
            toast.error("Diçka shkoi keq. Ju lutem provoni përsëri!");
        });
    }

    return (
        <div>
            <form onSubmit={submitData}>
                <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    label="Emri i kompanisë"
                    name="company_name"
                    value={companyName}
                    className="my-2"
                    required
                    onChange={e => setCompanyName(e.target.value)}
                />
                <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    label="Pozita"
                    name="position"
                    value={position}
                    className="my-2"
                    required
                    onChange={e => setPosition(e.target.value)}
                />
                <TextField
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    type="date"
                    variant="outlined"
                    fullWidth
                    label="Data e fillimit"
                    name="join_date"
                    className="my-2"
                    required
                    value={joinDate}
                    onChange={e => setJoinDate(e.target.value)}
                />
                <TextField
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    type="date"
                    variant="outlined"
                    fullWidth
                    label="Data e mbarimit"
                    name="leave_date"
                    className="my-2"
                    required={!stillWorking}
                    value={leaveDate}
                    onChange={e => setLeaveDate(e.target.value)}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={stillWorking}
                            onChange={e => setStillWorking(e.target.checked)}
                            name="still_working"
                            color="primary"
                            size="small"
                        />
                    }
                    label="Prezent"
                />
                <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    label="Shteti"
                    name="country"
                    value={country}
                    className="my-2"
                    required
                    onChange={e => setCountry(e.target.value)}
                />
                <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    label="Qyteti"
                    name="city"
                    className="my-2"
                    required
                    value={city}
                    onChange={e => setCity(e.target.value)}
                />
                <div className="my-2">
                    <CKEditor
                        editor={ClassicEditor}
                        data={description}
                        onChange={(e, editor) => setDescription(editor.getData())}
                        config={{         
                            toolbar: ['heading', '|', 'bold', 'italic', 'numberedList', 'bulletedList']
                        }}  
                    />
                </div>
                <div className='mt-4 text-center'>
                    <button className={styles.SubmitButton}>Ruaj</button>
                </div>
            </form>
        </div>
    );
};

export default ExperienceForm;