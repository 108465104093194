import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';
import styles from './Auth.module.scss';
import { TextField } from '@mui/material';
import { changePasswordWithCode } from '../../redux/actions/auth';
import { toast } from "react-toastify";

const Forget = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token, email } = useParams();

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emaili, setEmaili] = useState(email ?? "");
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();

    const onResetPassword = (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            "email": emaili,
            "password": password,
            "password_confirmation": confirmPassword,
            "reset_password_code": token
        }

        if(password === confirmPassword){
            dispatch(changePasswordWithCode(data)).then(res => {
                toast.success("Fjalëkalimi juaj u ndryshua me sukses");
                setLoading(false);
                navigate('/login');
            }).catch(err => {
                toast.error("Diçka shkoi keq. Ju lutem provoni përsëri!");
                setLoading(false);
            });
        }else{
            setError(true);
        }
    }

    return (
        <div className='text-center mt-5'>
            <form onSubmit={onResetPassword}>
                <h4 className={styles.FormTitle}>Rivendos fjalëkalimin!</h4>
                <div>
                    <TextField 
                        id="outlined-basic" 
                        size="small" 
                        type="email"
                        variant="outlined" 
                        onChange={e => setEmaili(e.target.value)} 
                        className="my-2" 
                        value={emaili} 
                        label="E-mail"
                        style={{width: "300px"}}
                        required
                    />
                </div>
                <div>
                    <TextField 
                        id="outlined-basic" 
                        size="small" 
                        type="password"
                        variant="outlined" 
                        onChange={e => setPassword(e.target.value)} 
                        className="my-2" 
                        value={password} 
                        label="Fjalëkalimi"
                        style={{width: "300px"}}
                        required
                    />
                </div>
                <div>
                    <TextField 
                        id="outlined-basic" 
                        size="small" 
                        type="password"
                        variant="outlined" 
                        onChange={e => setConfirmPassword(e.target.value)} 
                        className="my-2" 
                        value={confirmPassword} 
                        label="Konfirmo fjalëkalimin"
                        style={{width: "300px"}}
                        required
                        error={error && password !== confirmPassword}
                        helperText={(error && password !== confirmPassword && "Fjalëkalimet nuk përputhen")}
                    />
                </div>
                <button className={styles.Button} type="submit" disabled={loading}>
                    Ndrysho fjalëkalimin
                </button>
            </form>
        </div>
    );
};

export default Forget;