import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { TextField, Autocomplete } from '@mui/material';
import { getJobSeekerProfile, updateProfile } from '../../../../redux/actions/jobseeker/profile';
import { toast } from "react-toastify";
import { toggleDrawer } from '../../../../redux/actions/app';
import styles from './Forms.module.scss';

const ProfileForm = (props) => {
    const dispatch = useDispatch();
    const { profile } = props;
    const countries = useSelector((state) => state.app.countries);

    const [cities, setCities] = useState([]);
    const [name, setName] = useState(profile.name);
    const [surname, setSurname] = useState(profile.surname);
    const [title, setTitle] = useState(profile.title);
    const [phone, setPhone] = useState(profile.phone_number);
    const [country, setCountry] = useState(profile.country_name);
    const [city, setCity] = useState(profile.city_name);
    const [birthday, setBirthday] = useState(profile.date_of_birth);
    const [biography, setBiography] = useState(profile.biography);
    const [linkedin, setLinkedin] = useState(profile.linkedin_url);
    const [facebook, setFacebook] = useState(profile.facebook_url);
    const [instagram, setInstagram] = useState(profile.instagram_url);

    useEffect(() => {
        if(country){
            countries.forEach(element => {
                if(element.country_iso === country){
                    setCities(element.cities);
                }
            });
        }
    }, [country, countries]);

    const onUpdateProfileHandler = (e) => {
        e.preventDefault();
        const cityFound = cities.find(c => c.name === city);
        const countryFound = countries.find(c => c.country_name === country);
        const data = {
            "name": name,
            "surname": surname,
            "title": title,
            "country_name": country,
            "country_value": countryFound.country_iso,
            "city_name": city,
            "city_value": cityFound.value,
            "phone": phone,
            "birthday": birthday,
            "biography": biography,
            "linkedin": linkedin,
            "facebook": facebook,
            "instagram": instagram
        };

        dispatch(updateProfile(data)).then(res => {
            dispatch(getJobSeekerProfile()).then(res => {
                toast.success("Profili u ndryshua me sukses!");
                dispatch(toggleDrawer(false));
            }).catch(err => {
                toast.error("Diçka shkoi keq. Ju lutem rifreskoni faqen!");
            });
        }).catch(err => {
            toast.error("Diçka shkoi keq. Ju lutem provoni përsëri!");
        });
    };

    return (
        <div>
            <form onSubmit={onUpdateProfileHandler}>
                <TextField 
                    id="outlined-basic" 
                    size="small"
                    variant="outlined" 
                    onChange={e => setName(e.target.value)} 
                    className="mb-2" 
                    value={name} 
                    label="Emri" 
                    fullWidth
                    required
                />
                <TextField 
                    id="outlined-basic" 
                    size="small" 
                    variant="outlined" 
                    onChange={e => setSurname(e.target.value)} 
                    className="my-2" 
                    value={surname} 
                    label="Mbiemri" 
                    fullWidth
                    required
                />
                <TextField 
                    id="outlined-basic" 
                    size="small" 
                    variant="outlined" 
                    onChange={e => setTitle(e.target.value)} 
                    className="my-2" 
                    value={title} 
                    label="Titulli" 
                    fullWidth
                />
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size='small'
                    className="my-2" 
                    options={countries.map((option) => option.country_name)}
                    value={country}
                    onChange={(e, newValue) => setCountry(newValue)}
                    renderInput={(params) => <TextField {...params} label="Shteti" required />}
                />
                <Autocomplete
                    disablePortal
                    disabled={!country}
                    id="combo-box-demo"
                    size='small'
                    className="my-2" 
                    options={cities.map((option) => option.name)}
                    value={city}
                    onChange={(e, newValue) => setCity(newValue)}
                    renderInput={(params) => <TextField {...params} label="Qyteti" required />}
                />
                <TextField 
                    id="outlined-basic" 
                    size="small"
                    variant="outlined" 
                    onChange={e => setPhone(e.target.value)} 
                    className="my-2" 
                    value={phone} 
                    label="Numri kontaktues" 
                    fullWidth
                    required
                />
                <TextField
                    InputLabelProps={{ shrink: true }}
                    id="outlined-basic" 
                    size="small"
                    type="date"
                    variant="outlined" 
                    onChange={e => setBirthday(e.target.value)} 
                    className="my-2" 
                    value={birthday} 
                    label="Datëlindja"
                    fullWidth
                />
                <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    className='my-2'
                    label="Biografi e shkurtër"
                    name="biography"
                    value={biography}
                    onChange={(e) => setBiography(e.target.value)}
                    rows={4}
                    multiline
                />
                <TextField 
                    id="outlined-basic" 
                    size="small"
                    variant="outlined" 
                    onChange={e => setLinkedin(e.target.value)} 
                    className="my-2" 
                    value={linkedin} 
                    label="Linkedin" 
                    fullWidth
                />
                <TextField 
                    id="outlined-basic" 
                    size="small"
                    variant="outlined" 
                    onChange={e => setFacebook(e.target.value)} 
                    className="my-2" 
                    value={facebook} 
                    label="Facebook" 
                    fullWidth
                />
                <TextField 
                    id="outlined-basic" 
                    size="small"
                    variant="outlined" 
                    onChange={e => setInstagram(e.target.value)} 
                    className="my-2" 
                    value={instagram} 
                    label="Instagram" 
                    fullWidth
                />
                <div className='mt-4 text-center'>
                    <button className={styles.SubmitButton}>Ndrysho</button>
                </div>
            </form>
        </div>
    );
};

export default ProfileForm;