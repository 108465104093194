import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from '@mui/material';
import { useParams } from "react-router-dom";
import { getJob } from '../../redux/actions/jobs/jobs';
import { getMyJobApplications } from '../../redux/actions/jobseeker/jobs';
import SingleJob from '../../components/job-component/SingleJob';
import styles from "./Job.module.scss";
import ApplyJobModal from '../../components/apply-job-modal/ApplyJobModal';

const JobPage = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const job = useSelector((state) => state.jobs.job);
    const user = useSelector((state) => state.auth.user);

    const [loading, setLoading] = useState(true);
    const [openApplyModal, setOpenApplyModal] = useState(false);
    const [alreadyApplied, setAlreadyApplied] = useState(false);

    useEffect(() => {
        dispatch(getJob(id)).then(res => {
            dispatch(getMyJobApplications()).then(res => {
                const findJob = res.data.find(app => app.job_id === parseInt(id));
                if(findJob){
                    setAlreadyApplied(true);
                }else{
                    setAlreadyApplied(false);
                }
                setLoading(false);
            }).catch(err => {
                setLoading(false);
            })
        }).catch(err => {
            setLoading(false);
        });
    }, [dispatch, id]);

    const onSelectJob = () => {
        setOpenApplyModal(true);
    }

    const JobImage = () => {
        if (job && job.job_image_file_name) {
            return <img src={`https://api.hrbee.ai/images-files/${job.job_image_file_name}`}></img>;
        }
    }


    return (
        <div>
            <div className='container mb-5'>
                {loading && (
                    <div className='text-center mt-5'>
                        <CircularProgress/>
                    </div>
                )}
                {!loading && (
                    <div className={styles.JobPageWrapper}>
                        <div>
                            <SingleJob job={job} onApplyClick={() => onSelectJob()} alreadyApplied={alreadyApplied} />
                        </div>
                        <div>
                            <div className={styles.JobDescription}>
                                <JobImage></JobImage>
                                <div style={{ fontSize: '13px' }} dangerouslySetInnerHTML={{__html: job.description}} /> 
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <ApplyJobModal job={job} open={openApplyModal} onClose={() => setOpenApplyModal(false)} user={user} />
        </div>
    );
};

export default JobPage;