import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { toggleDrawer } from '../../redux/actions/app';
import { Drawer, IconButton, styled } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import styles from "./RightDrawer.module.scss";

const RightDrawerHeader = styled('div')(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2, 2, 0, 2),
    zIndex: theme.zIndex.drawer + 2
}));

const RightDrawerContainer = styled('div')(({theme}) => ({
    padding: theme.spacing(2),
}));

const RightDrawer = (props) => {
    const isMobile = window.innerWidth < 550;

    const dispatch = useDispatch();
    const isDrawerOpen = useSelector((state) => state.app.rightDrawer);

    const sxConfig = {
        width: isMobile ? 320 : 520,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            width: isMobile ? 320 :520,
            boxSizing: 'border-box',
        }
    };

    const handleCloseDrawer = () => {
        dispatch(toggleDrawer(false));
        props.onClose && props.onClose();
    }

    return (
        <Drawer
            sx={sxConfig}
            anchor="right"
            open={isDrawerOpen}
            onClose={handleCloseDrawer}>
            <RightDrawerHeader>
                <div className={styles.DrawerTitle}>
                    {props.title}
                </div>
                <IconButton aria-label="delete" size="medium" onClick={handleCloseDrawer}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </RightDrawerHeader>
            <hr></hr>
            <RightDrawerContainer style={{marginTop: "-20px"}}>
                {props.children}
            </RightDrawerContainer>
        </Drawer>
    );
}

export default RightDrawer;