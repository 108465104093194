import React, { useEffect, useState } from 'react';
import { Tabs, Tab, IconButton, Menu, MenuItem, CircularProgress, Button } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { getJobSeekerProfile } from '../../../redux/actions/jobseeker/profile';
import RightDrawer from '../../../components/right-drawer/RightDrawer';
import { toggleDrawer } from '../../../redux/actions/app';
import LabelValue from '../../../components/label-value/LabelValue';
import moment from 'moment';
import { deleteExperience, deleteEducation, deleteTraining, deleteLanguage, deleteSkill, deleteLicense, uploadProfilePicture } from '../../../redux/actions/jobseeker/profile';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExperienceForm from './forms/ExperienceForm';
import EducationForm from './forms/EducationForm';
import TrainingForm from './forms/TrainingForm';
import LanguageForm from './forms/LanguageForm';
import ComputerSkillsForm from './forms/ComputerSkillsForm';
import OtherSkillsForm from './forms/OtherSkillsForm';
import LicenseForm from './forms/LicenseForm';
import ProfileForm from './forms/ProfileForm';
import styles from './Profile.module.scss';
import { toast } from "react-toastify";
import { documentsUrl } from "../../../global";

const Profile = () => {
    const dispatch = useDispatch();

    const jobseekerProfile = useSelector((state) => state.jobseeker.profile);

    const [activeTab, setActiveTab] = useState(0);
    const [form, setForm] = useState('');
    const [anchorEl, setAnchorEl] = useState();
    const [selectData, setSelectData] = useState();
    const [anchorElData, setAnchorElData] = useState();

    const [image, setImage] = useState();
    const [loadingImageUpload, setLoadingImageUpload] = useState();


    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickMenuData = (e, data, form) => {
        setForm(form);
        setAnchorElData(e.currentTarget);
        setSelectData(data);
    }

    const onOpenFormData = () => {
        setAnchorElData("");
        dispatch(toggleDrawer(true));
    }

    const deleteData = () => {
        if(form === "experience"){
            dispatch(deleteExperience(selectData.seq_id)).then(res => {
                toast.success("Eksperienca u fshi me sukses!");
                dispatch(getJobSeekerProfile()).then(res => {}).catch(err => {
                    toast.error("Diçka shkoi keq. Ju lutem rifreskoni faqen!");
                })
            }).catch(err => {
                toast.error("Diçka shkoi keq. Ju lutem provoni përsëri!");
            });
        }
        if(form === "education"){
            dispatch(deleteEducation(selectData.seq_id)).then(res => {
                toast.success("Edukimi u fshi me sukses!");
                dispatch(getJobSeekerProfile()).then(res => {}).catch(err => {
                    toast.error("Diçka shkoi keq. Ju lutem rifreskoni faqen!");
                })
            }).catch(err => {
                toast.error("Diçka shkoi keq. Ju lutem provoni përsëri!");
            });
        }
        if(form === "training"){
            dispatch(deleteTraining(selectData.seq_id)).then(res => {
                toast.success("Trajnimi u fshi me sukses!");
                dispatch(getJobSeekerProfile()).then(res => {}).catch(err => {
                    toast.error("Diçka shkoi keq. Ju lutem rifreskoni faqen!");
                })
            }).catch(err => {
                toast.error("Diçka shkoi keq. Ju lutem provoni përsëri!");
            });
        }
        if(form === "language"){
            dispatch(deleteLanguage(selectData.seq_id)).then(res => {
                toast.success("Gjuha u fshi me sukses!");
                dispatch(getJobSeekerProfile()).then(res => {}).catch(err => {
                    toast.error("Diçka shkoi keq. Ju lutem rifreskoni faqen!");
                })
            }).catch(err => {
                toast.error("Diçka shkoi keq. Ju lutem provoni përsëri!");
            });
        }
        if(form === "computer-skills"){
            dispatch(deleteSkill(selectData.seq_id)).then(res => {
                toast.success("Aftesia u fshi me sukses!");
                dispatch(getJobSeekerProfile()).then(res => {}).catch(err => {
                    toast.error("Diçka shkoi keq. Ju lutem rifreskoni faqen!");
                })
            }).catch(err => {
                toast.error("Diçka shkoi keq. Ju lutem provoni përsëri!");
            });
        }
        if(form === "other-skills"){
            dispatch(deleteSkill(selectData.seq_id)).then(res => {
                toast.success("Aftesia u fshi me sukses!");
                dispatch(getJobSeekerProfile()).then(res => {}).catch(err => {
                    toast.error("Diçka shkoi keq. Ju lutem rifreskoni faqen!");
                })
            }).catch(err => {
                toast.error("Diçka shkoi keq. Ju lutem provoni përsëri!");
            });
        }
        if(form === "licenses"){
            dispatch(deleteLicense(selectData.seq_id)).then(res => {
                toast.success("Licensa u fshi me sukses!");
                dispatch(getJobSeekerProfile()).then(res => {}).catch(err => {
                    toast.error("Diçka shkoi keq. Ju lutem rifreskoni faqen!");
                })
            }).catch(err => {
                toast.error("Diçka shkoi keq. Ju lutem provoni përsëri!");
            });
        }
        setAnchorElData("");
    }

    const handleCloseMenu = () => {
        setAnchorEl("");
        setAnchorElData("");
    };

    const handleChangeTab = (event, newValue) => {
        setActiveTab(newValue);
    };

    useEffect(() => {
        dispatch(getJobSeekerProfile());
    }, [dispatch]);

    const onOpenForm = (form) => {
        setAnchorEl("");
        setForm(form);
        dispatch(toggleDrawer(true));
    }

    const uploadImage = (e) => {
        setLoadingImageUpload(true)
        const formData = new FormData()
        formData.append('file', e.target.files[0]);
        formData.append('upload_type', 'jobseeker_profile_image');
        formData.append('job_seeker_id', jobseekerProfile.user.id);
        dispatch(uploadProfilePicture(formData)).then(res => {
            dispatch(getJobSeekerProfile());
            setLoadingImageUpload(false);
        }).catch(error => {
            setLoadingImageUpload(false)
            toast.error('Diçka shkoi keq, ju lutem provoni përsëri');
        });
    }

    return (
        <div className='container'>
            {jobseekerProfile && (
                <>
                    <div className={styles.ProfileWrapper}>
                        <div className='row'>
                            <div className='col-md-4 mt-3'>
                                <div className={styles.MainProfileWrapperBox}>
                                    <div className='d-flex justify-content-center'>
                                        <div>
                                            {loadingImageUpload ? (
                                                <div className={`${styles.ImageProfile} d-flex justify-content-center align-items-center`}>
                                                    <CircularProgress color="primary" fontSize="small" className="mt-1" />
                                                </div>
                                            ) : (
                                                jobseekerProfile.jobseeker_profile.image_url !== null  ? (
                                                    <div className={styles.ImageProfile} style={{backgroundImage: `url(${documentsUrl}${jobseekerProfile.jobseeker_profile.image_url})`}}>
                                                    </div>
                                                ) : (
                                                    <div className={styles.ImageProfile}>
                                                    </div>
                                                )
                                            )}                                        
                                            <Button
                                                variant="contained"
                                                component="label"
                                                size='small'
                                                fontSize="small"
                                                style={{marginLeft: '12px'}}
                                            >
                                                {jobseekerProfile.jobseeker_profile.image_url === null ? 'Shto foto' : 'Ndrysho'}
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    hidden
                                                    onChange={uploadImage}
                                                />
                                            </Button>
                                        </div>
                                    </div>
                                    <IconButton className={styles.MoreIconButton} onClick={handleClickMenu}>
                                        <MoreVertIcon />
                                    </IconButton>
                                    <div className={styles.ProfileDescription}>
                                        <LabelValue label="Emri & Mbiemri" value={`${jobseekerProfile.jobseeker_profile.name ?? "~"} ${jobseekerProfile.jobseeker_profile.surname ?? "~"}`} />
                                    </div>
                                    <div className={styles.ProfileDescription}>
                                        <LabelValue label="Titulli" value={jobseekerProfile.jobseeker_profile.title ?? "~"} />
                                    </div>
                                    <div className={styles.ProfileDescription}>
                                        <LabelValue label="Datëlindja" value={jobseekerProfile.jobseeker_profile.date_of_birth ?? "~"} />
                                    </div>
                                    <div className={styles.ProfileDescription}>
                                        <LabelValue label="Shteti" value={jobseekerProfile.jobseeker_profile.country_name ?? "~"} />
                                    </div>
                                    <div className={styles.ProfileDescription}>
                                        <LabelValue label="Qyteti" value={jobseekerProfile.jobseeker_profile.city_name ?? "~"} />
                                    </div>
                                    <div className={styles.ProfileDescription}>
                                        <LabelValue label="Numri kontaktues" value={jobseekerProfile.jobseeker_profile.phone_number ?? "~"} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-8 mt-3'>
                                <div className={styles.SecondProfileWrapperBox}>
                                    <IconButton className={styles.MoreIconButton}  onClick={handleClickMenu}>
                                        <MoreVertIcon />
                                    </IconButton>
                                    <div className='mt-4'>
                                        <div className={styles.ProfileDescription}>
                                            <LabelValue label="Biografi e shkurtër" value={jobseekerProfile.jobseeker_profile.biography ?? "~"} />
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                open={anchorEl}
                                onClose={handleCloseMenu}
                                className={styles.MenuItems}
                            >
                                <MenuItem onClick={() => onOpenForm('profile')}>Ndrysho</MenuItem>
                            </Menu>
                        </div>
                    </div>
                    <div className={styles.ProfileTabsWrapper}>
                        <Tabs 
                            value={activeTab} 
                            onChange={handleChangeTab} 
                            variant="scrollable"
                            scrollButtons={true}
                            allowScrollButtonsMobile
                        >
                            <Tab label="Përvoja e punës" value={0} />
                            <Tab label="Edukimi" value={1} />
                            <Tab label="Trajnimet" value={2} />
                            <Tab label="Gjuhët" value={3} />
                            <Tab label="Aftësitë kompjuterike" value={4} />
                            <Tab label="Aftësitë tjera" value={5} />
                            <Tab label="Licensa" value={6} />
                        </Tabs>
                    </div>
                    <div className={styles.DataProfileWrapper}>
                        {activeTab === 0 && (
                            <div>
                                <button className={styles.AddNewDataButton} onClick={() => onOpenForm('experience')}>Shto përvojë pune</button>
                                {jobseekerProfile.jobseeker_experiences.length > 0 ? (
                                    jobseekerProfile.jobseeker_experiences.map(experience => (
                                        <div key={experience.id} className={styles.DataProfileItemWrapperExperience}>
                                            <IconButton className={styles.MoreIconButton} onClick={(e) => handleClickMenuData(e, experience, 'experience')}>
                                                <MoreVertIcon />
                                            </IconButton>
                                            <p className={styles.Position}>{experience.position ?? "~"}</p>
                                            <p className={styles.Company}>{experience.company_name ?? "~"} <span>({experience.country ?? "~"} - {experience.city ?? "~"})</span></p>
                                            <p className={styles.Dates}>{moment(experience.join_date).calendar() ?? "~"} - {parseInt(experience.still_working) === 1 ? "Prezent" : moment(experience.leave_date).calendar() ?? "~"}</p>
                                            <div className={styles.Description}>
                                                <div style={{ fontSize: '13px' }} dangerouslySetInnerHTML={{__html: experience.description ?? "~"}} /> 
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p className={styles.NoDataFound}>Nuk keni të dhëna rreth përvojës së punës!</p>
                                )}
                            </div>
                        )}
                        {activeTab === 1 && (
                            <div>
                                <button className={styles.AddNewDataButton} onClick={() => onOpenForm('education')}>Shto edukimin</button>
                                {jobseekerProfile.jobseeker_educations.length > 0 ? (
                                    jobseekerProfile.jobseeker_educations.map(education => (
                                        <div key={education.id} className={styles.DataProfileItemWrapperEducation}>
                                            <IconButton className={styles.MoreIconButton} onClick={(e) => handleClickMenuData(e, education, 'education')}>
                                                <MoreVertIcon />
                                            </IconButton>
                                            <div className='row'>
                                                <div className={education.file ? "col-md-4 text-center" : "col-md-6 text-center"}>
                                                    <LabelValue label="Shkolla" value={education.school ?? "~"} />
                                                </div>
                                                <div className={education.file ? "col-md-4 text-center" : "col-md-6 text-center"}>
                                                    <LabelValue label="Drejtimi" value={education.field_of_study ?? "~"} />
                                                </div>
                                                {education.file && (
                                                    <div className='col-md-4 text-center'>
                                                        <LabelValue label="Dokumenti" type="link" value={education.file ?? "~"} />
                                                    </div>
                                                )}
                                            </div>
                                            <div className={styles.Divider}></div>
                                            <div className='row'>
                                                <div className='col-md-3 text-center'>
                                                    <LabelValue label="prej - deri" value={`${moment(education.from_date).calendar() ?? "~"} - ${parseInt(education.ongoing) === 1 ? "Prezent" : moment(education.to_date).calendar() ?? "~"}`} />
                                                </div>
                                                <div className='col-md-3 text-center'>
                                                    <LabelValue label="Niveli" value={education.degree ?? "~"} />
                                                </div>
                                                <div className='col-md-3 text-center'>
                                                    <LabelValue label="Shteti" value={education.country ?? "~"} />
                                                </div>
                                                <div className='col-md-3 text-center'>
                                                    <LabelValue label="Qyteti" value={education.city ?? "~"} />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p className={styles.NoDataFound}>Nuk keni të dhëna rreth edukimit!</p>
                                )}
                            </div>
                        )}
                        {activeTab === 2 && (
                            <div>
                                <button className={styles.AddNewDataButton} onClick={() => onOpenForm('training')}>Shto trajnim</button>
                                {jobseekerProfile.jobseeker_trainings.length > 0 ? (
                                    jobseekerProfile.jobseeker_trainings.map(training => (
                                        <div key={training.id} className={styles.DataProfileItemWrapperTraining}>
                                            <IconButton className={styles.MoreIconButton} onClick={(e) => handleClickMenuData(e, training, 'training')}>
                                                <MoreVertIcon />
                                            </IconButton>
                                            <div className='row'>
                                                <div className={training.file ? "col-md-4 text-center" : "col-md-6 text-center"}>
                                                    <LabelValue label="Instituti" value={training.company_name ?? "~"} />
                                                </div>
                                                <div className={training.file ? "col-md-4 text-center" : "col-md-6 text-center"}>
                                                    <LabelValue label="Trajnimi" value={training.position ?? "~"} />
                                                </div>
                                                {training.file && (
                                                    <div className='col-md-4 text-center'>
                                                        <LabelValue label="Dokumenti" type="link" value={training.file ?? "~"} />
                                                    </div>
                                                )}
                                            </div>
                                            <div className={styles.Divider}></div>
                                            <div className='row'>
                                                <div className='col-md-4 text-center'>
                                                    <LabelValue label="prej - deri" value={`${moment(training.join_date).calendar() ?? "~"} - ${parseInt(training.still_working) === 1 ? "Prezent" : moment(training.leave_date).calendar() ?? "~"}`} />
                                                </div>
                                                <div className='col-md-4 text-center'>
                                                    <LabelValue label="Shteti" value={training.country ?? "~"} />
                                                </div>
                                                <div className='col-md-4 text-center'>
                                                    <LabelValue label="Qyteti" value={training.city ?? "~"} />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p className={styles.NoDataFound}>Nuk keni të dhëna rreth trajnimeve!</p>
                                )}
                            </div>
                        )}
                        {activeTab === 3 && (
                            <div>
                                <button className={styles.AddNewDataButton} onClick={() => onOpenForm('language')}>Shto gjuhë</button>
                                {jobseekerProfile.jobseeker_languages.length > 0 ? (
                                    jobseekerProfile.jobseeker_languages.map(language => (
                                        <div key={language.id} className={styles.DataProfileItemWrapperLanguages}>
                                            <IconButton className={styles.MoreIconButton} onClick={(e) => handleClickMenuData(e, language, 'language')}>
                                                <MoreVertIcon />
                                            </IconButton>
                                            <div className='row'>
                                                <div className='col-md-12 text-center'>
                                                    <LabelValue label="Gjuha" value={language.language_value ?? "~"} />
                                                </div>
                                            </div>
                                            <div className={styles.Divider}></div>
                                            <div className='row'>
                                                <div className='col-md-3 text-center'>
                                                    <LabelValue label="Të lexuarit" value={language.reading === "NATIVE" ? "Gjuhë amë" : language.reading === "FULL_PROFESSIONAL" ? "Aftësi të zgjeruara profesionale" : language.reading === "PROFESSIONAL_WORKING" ? "Për punë profesionale" : language.reading === "LIMITED_WORKING" ? "E limituar për punë" : "Elementare"} />
                                                </div>
                                                <div className='col-md-3 text-center'>
                                                    <LabelValue label="Të kuptuarit" value={language.listening === "NATIVE" ? "Gjuhë amë" : language.listening === "FULL_PROFESSIONAL" ? "Aftësi të zgjeruara profesionale" : language.listening === "PROFESSIONAL_WORKING" ? "Për punë profesionale" : language.listening === "LIMITED_WORKING" ? "E limituar për punë" : "Elementare"} />
                                                </div>
                                                <div className='col-md-3 text-center'>
                                                    <LabelValue label="Të folurit" value={language.speaking === "NATIVE" ? "Gjuhë amë" : language.speaking === "FULL_PROFESSIONAL" ? "Aftësi të zgjeruara profesionale" : language.speaking === "PROFESSIONAL_WORKING" ? "Për punë profesionale" : language.speaking === "LIMITED_WORKING" ? "E limituar për punë" : "Elementare"} />
                                                </div>
                                                <div className='col-md-3 text-center'>
                                                    <LabelValue label="Të shkruarit" value={language.writing === "NATIVE" ? "Gjuhë amë" : language.writing === "FULL_PROFESSIONAL" ? "Aftësi të zgjeruara profesionale" : language.writing === "PROFESSIONAL_WORKING" ? "Për punë profesionale" : language.writing === "LIMITED_WORKING" ? "E limituar për punë" : "Elementare"} />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p className={styles.NoDataFound}>Nuk keni të dhëna rreth gjuhëve!</p>
                                )}
                            </div>
                        )}
                        {activeTab === 4 && (
                            <div>
                                <button className={styles.AddNewDataButton} onClick={() => onOpenForm('computer-skills')}>Shto aftësi</button>
                                <div className='row'>
                                    {jobseekerProfile.jobseeker_computer_skills.length > 0 ? (
                                        jobseekerProfile.jobseeker_computer_skills.map(skill => (
                                            <div key={skill.id} className={`col-md-6 text-center ${styles.SmallBox}`}>
                                                <IconButton className={styles.MoreIconButton} onClick={(e) => handleClickMenuData(e, skill, 'computer-skills')}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <div className={styles.DataProfileItemWrapperSkill}>
                                                    <div className='row'>
                                                        <div className='col-md-6 text-center'>
                                                            <LabelValue label="Aftësia" value={skill.skill_name ?? "~"} />
                                                        </div>
                                                        <div className='col-md-6 text-center'>
                                                            <LabelValue label="Niveli" value={skill.level === "Professional" ? "Profesional" : skill.level === "Excellent" ? "Shkëlqyeshëm" : skill.level === "Average" ? "Mesatare" : skill.level === "Advanced" ? "Avancuar" : "Fillestar" } />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p className={styles.NoDataFound}>Nuk keni të dhëna rreth aftësive kompjuterike!</p>
                                    )}
                                </div>
                            </div>
                        )}
                        {activeTab === 5 && (
                            <div>
                                <button className={styles.AddNewDataButton} onClick={() => onOpenForm('other-skills')}>Shto aftësi</button>
                                <div className='row'>
                                    {jobseekerProfile.jobseeker_other_skills.length > 0 ? (
                                        jobseekerProfile.jobseeker_other_skills.map(skill => (
                                            <div key={skill.id} className={`col-md-6 text-center ${styles.SmallBox}`}>
                                                <IconButton className={styles.MoreIconButton} onClick={(e) => handleClickMenuData(e, skill, 'other-skills')}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <div className={styles.DataProfileItemWrapperSkill}>
                                                    <div className='row'>
                                                        <div className='col-md-6 text-center'>
                                                            <LabelValue label="Aftësia" value={skill.skill_name ?? "~"} />
                                                        </div>
                                                        <div className='col-md-6 text-center'>
                                                            <LabelValue label="Niveli" value={skill.level === "Professional" ? "Profesional" : skill.level === "Excellent" ? "Shkëlqyeshëm" : skill.level === "Average" ? "Mesatare" : skill.level === "Advanced" ? "Avancuar" : "Fillestar" } />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p className={styles.NoDataFound}>Nuk keni të dhëna rreth aftësive tjera!</p>
                                    )}
                                </div>
                            </div>
                        )}
                        {activeTab === 6 && (
                            <div>
                                <button className={styles.AddNewDataButton} onClick={() => onOpenForm('licenses')}>Shto licensë</button>
                                <div className='row'>
                                    {jobseekerProfile.jobseeker_driving_licenses.length > 0 ? (
                                        jobseekerProfile.jobseeker_driving_licenses.map(license => (
                                            <div key={license.id} className={`col-md-6 text-center ${styles.SmallBox}`}>
                                                <IconButton className={styles.MoreIconButton} onClick={(e) => handleClickMenuData(e, license, 'licenses')}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <div className={styles.DataProfileItemWrapperLicense}>
                                                    <LabelValue label="Licensa" value={license.driving_category ?? "~"} />
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p className={styles.NoDataFound}>Nuk keni të dhëna rreth licensave!</p>
                                    )}
                                </div>
                            </div>
                        )}
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorElData}
                            open={anchorElData}
                            onClose={handleCloseMenu}
                            className={styles.MenuItems}
                        >
                            <MenuItem onClick={() => onOpenFormData()}>Ndrysho</MenuItem>
                            <MenuItem onClick={() => deleteData()}>Fshije</MenuItem>
                        </Menu>
                    </div>

                    <RightDrawer title={form === "experience" ? "Përvojë pune" : form === "education" ? "Edukimi" : form === "training" ? "Trajnim" : form === "language" ? "Gjuhë" : form === "computer-skills" ? "Afëesi kompjuterike" : form === "other-skills" ? "Aftësi tjera" : form === "licenses" ? "Licensa" : "Profili"}>
                        {form === "experience" && <ExperienceForm selectedData={selectData} onFinish={() => setSelectData()} />}
                        {form === "education" && <EducationForm selectedData={selectData} onFinish={() => setSelectData()} />}
                        {form === "training" && <TrainingForm selectedData={selectData} onFinish={() => setSelectData()} />}
                        {form === "language" && <LanguageForm selectedData={selectData} onFinish={() => setSelectData()} />}
                        {form === "computer-skills" && <ComputerSkillsForm selectedData={selectData} onFinish={() => setSelectData()} />}
                        {form === "other-skills" && <OtherSkillsForm selectedData={selectData} onFinish={() => setSelectData()} />}
                        {form === "licenses" && <LicenseForm selectedData={selectData} onFinish={() => setSelectData()} />}
                        {form === "profile" && <ProfileForm profile={jobseekerProfile.jobseeker_profile} onFinish={() => setSelectData()} />}
                    </RightDrawer>
                </>
            )}
        </div>
    );
};

export default Profile;