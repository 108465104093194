import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { TextField, InputLabel, FormControl, Select, MenuItem, } from '@mui/material';
import styles from "./Forms.module.scss";
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import { getJobSeekerProfile, storeSkill, updateSkill } from '../../../../redux/actions/jobseeker/profile';
import { toggleDrawer } from '../../../../redux/actions/app';

const OtherSkillsForm = (props) => {
    const { onFinish, selectedData } = props;
    const dispatch = useDispatch();

    const skillsLevels = useSelector((state) => state.app.skills_levels);

    const [skill, setSkill] = useState(selectedData ? selectedData.skill_name : '');
    const [level, setLevel] = useState(selectedData ? selectedData.level : '');

    const submitData = (e) => {
        e.preventDefault();
        let data = {
            "skill_name": skill,
            "skill_value": skill,
            "level": level,
            "type": "other",
        };
        if(selectedData){
            data = {...data, "skill_id": selectedData.seq_id}
        }
        dispatch(selectedData ? updateSkill(data) : storeSkill(data)).then(res => {
            dispatch(getJobSeekerProfile()).then(res => {
                toast.success(selectedData ? "Aftesia u ndryshua me sukses" : "Aftesia u shtua me sukses");
                dispatch(toggleDrawer(false));
                onFinish();
            }).catch(err => {
                toast.error("Diçka shkoi keq. Ju lutem rifreskoni faqen!");
            });
        }).catch(err => {
            toast.error("Diçka shkoi keq. Ju lutem provoni përsëri!");
        });
    }

    return (
        <div>
            <form onSubmit={submitData}>
                <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    required
                    label="Aftësi tjera, Rekrutim, Planifikim strategjik..."
                    name="skill_name"
                    value={skill}
                    className="my-2"
                    onChange={e => setSkill(e.target.value)}
                />
                <FormControl variant="outlined" fullWidth={true} size="small" className="my-2">
                    <InputLabel id="demo-simple-select-outlined-label">Niveli</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Niveli"
                        name="level"
                        required
                        value={level}
                        onChange={e => setLevel(e.target.value)}
                    >
                        {skillsLevels && skillsLevels.map(level => (
                            <MenuItem key={level.value} value={level.value}>
                                {level.sq}
                            </MenuItem>     
                        ))}                     
                    </Select>
                </FormControl>
                <div className='mt-4 text-center'>
                    <button className={styles.SubmitButton}>Ruaj</button>
                </div>
            </form>
        </div>
    );
};

export default OtherSkillsForm;