import React, { useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setJobsFilters } from '../../redux/actions/jobs/jobs';
import { Button, Menu, MenuItem, IconButton } from '@mui/material';
import { logoutUser } from '../../redux/actions/auth';
import SearchIcon from '@mui/icons-material/Search';
import styles from './Nav.module.scss';
import HrBeeLogo from '../../assets/images/hr-jobs-logo.png';

const Nav = () => {
    const isMobile = window.innerWidth < 500;
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const filtersDialog = useSelector((state) => state.jobs.filters);
    const [anchorEl, setAnchorEl] = useState();

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl("");
    };

    const logOutHandler = () => {
        dispatch(logoutUser()).then(res => {
          navigate("/");
        });
    };

    const goToProfile = () => {
        navigate("/jobseeker/profile");
    };

    return (
        <div className={styles.NavWrapper}>
            <div className='container'>
                <div className={styles.Nav}>
                    <img src={HrBeeLogo} className={styles.Logo} alt="Logo" onClick={() => navigate("/")} />

                    <div className='d-flex align-items-center'>
                        {location.pathname === "/" && (
                            <div className='d-flex align-items-center'>
                                {!isMobile && (
                                    <IconButton onClick={() => dispatch(setJobsFilters(!filtersDialog))} className="me-3">
                                        <SearchIcon />
                                    </IconButton>
                                )}
                                <p className={styles.Link} onClick={() => dispatch(setJobsFilters(!filtersDialog))}>Filtro</p>
                            </div>
                        )}
                        {!user && (
                            <Link to="/publish-vacancy">Publiko konkursin</Link>
                        )}
                        {!user && (
                            <Link to="/login">Kyqu</Link>
                        )}
                        {user && user.user_type === 'job_seeker' && (
                            <div>
                                <Button aria-controls="simple-menu" className={styles.NavDropdownMenu} aria-haspopup="true" onClick={handleClickMenu}>
                                    {user.name} {user.surname}
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    open={anchorEl}
                                    onClose={handleCloseMenu}
                                >
                                    <MenuItem onClick={() => goToProfile()}>Profili im</MenuItem>
                                    <MenuItem onClick={() => logOutHandler()}>Dil nga llogaria</MenuItem>
                                </Menu>
                            </div>
                        )}   
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Nav;