import React, { useState, useEffect } from 'react';
import { MobileStepper, Button, TextField, InputLabel, FormControl, Select, MenuItem, Checkbox, FormControlLabel, Autocomplete } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { registerJobSeekerAndApply } from '../../redux/actions/auth';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import styles from './ApplyJobModal.module.scss';
import { toast } from "react-toastify";

const ApplyCV = (props) => {
    const dispatch = useDispatch();
    const { job, onClose } = props;

    const countries = useSelector((state) => state.app.countries);

    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(false);
    const [cities, setCities] = useState([]);

    const [name, setName] = useState();
    const [surname, setSurname] = useState();
    const [birthday, setBirthday] = useState();
    const [country, setCountry] = useState();
    const [city, setCity] = useState();
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [file, setFile] = useState();

    const handleNext = (e) => {
        e.preventDefault();
        submitData();
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleButtonClick = () => {
        document.getElementById('fileInput').click();
    };

    useEffect(() => {
        if(country){
            countries.forEach(element => {
                if(element.country_iso === country){
                    setCities(element.cities);
                }
            });
        }
    }, [country, countries]);

    const submitData = () => {
        setLoading(true);
        const cityFound = cities.find(c => c.name === city);
        const countryFound = countries.find(c => c.country_name === country);
        let data = new FormData();

        data.append("name", name);
        data.append("surname", surname);
        data.append("country_name", country);
        data.append("country_value", countryFound.country_iso);
        data.append("city_name", city);
        data.append("city_value", cityFound.value);
        data.append("phone", phone);
        data.append("email", email);
        data.append("password", password);
        data.append("confirm_password", confirmPassword);
        data.append("date_of_birth", birthday);
        data.append("job_id", job.job_id);
        data.append("company_id", job.company_id);

        if (file) {
            data.append("cv", file);
        }

        dispatch(registerJobSeekerAndApply(data)).then(res => {
            toast.success("Ju jeni regjistruar në HrBee dhe aplikimi për punë i është dërguar kompanisë!");
            onClose();
            setLoading(false);
        }).catch(err => {
            if(err.data.message === "email_alredy_exist"){
                toast.error("Ky email egziston. Ju lutem provoni të kyqeni!");
            }else{
                toast.error("Diçka shkoi keq. Ju lutem provoni përsëri!");
            }
            setLoading(false);
        });
    }

    return (
        <div>
            {activeStep === 0 && (
                <div>
                    <p className={styles.TextDescription}>Ju lutem plotësoni formën që të regjistroheni dhe të aplikoni për këtë pozitë</p>
                    <p className={styles.TextDescription}>Aplikimi do ti shkojë kompanisë përmes platformës tonë</p>
                </div>
            )}
            <form onSubmit={handleNext}>
                <div className={styles.RegisterForm}>
                    {/* Account Info */}
                    {activeStep === 0 && (
                        <div>
                            <div className='row'>
                                <div className='col-md-4 mt-3'>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        label="Emri"
                                        name="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className='col-md-4 mt-3'>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        label="Mbeimri"
                                        name="surname"
                                        value={surname}
                                        onChange={(e) => setSurname(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className='col-md-4 mt-3'>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        size="small"
                                        type="date"
                                        variant="outlined"
                                        fullWidth
                                        label="Datëlindja"
                                        name="birthday"
                                        value={birthday}
                                        onChange={e => setBirthday(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 mt-3'>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        size='small'
                                        options={countries.map((option) => option.country_name)}
                                        value={country}
                                        onChange={(e, newValue) => setCountry(newValue)}
                                        renderInput={(params) => <TextField {...params} label="Shteti" required />}
                                    />
                                </div>
                                <div className='col-md-6 mt-3'>
                                    <Autocomplete
                                        disablePortal
                                        disabled={!country}
                                        id="combo-box-demo"
                                        size='small'
                                        options={cities.map((option) => option.name)}
                                        value={city}
                                        onChange={(e, newValue) => setCity(newValue)}
                                        renderInput={(params) => <TextField {...params} label="Qyteti" required />}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 mt-3'>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        label="Nr Telefonit"
                                        name="phone"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className='col-md-6 mt-3'>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        label="E-mail"
                                        name="email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 mt-3'>
                                    <TextField
                                        size="small"
                                        type="password"
                                        variant="outlined"
                                        fullWidth
                                        label="Fjalëkalimi"
                                        name="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className='col-md-6 mt-3'>
                                    <TextField
                                        size="small"
                                        type="password"
                                        variant="outlined"
                                        fullWidth
                                        label="Konfirmo fjalëkalimin"
                                        name="confirmPassword"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        required
                                        error={errors && password !== confirmPassword}
                                        helperText={(errors && password !== confirmPassword && "Fjalëkalimet nuk përputhen")}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                <Button style={{ border: '1px solid #343349', marginTop: '20px', padding: '7px 50px' }} fullWidth onClick={handleButtonClick}>
                                    {file ? 'Ndrysho CV' : 'Ngarko CV'}
                                </Button>
                                <input
                                    id="fileInput"
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles.Divider}></div>
                <div className='d-flex justify-content-center'>
                    <button className={styles.ApplyButton} type="submit">
                        Apliko
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ApplyCV;