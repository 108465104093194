import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Nav from '../components/nav-bar/Nav';
import Footer from '../components/footer/Footer';

const JobSeekerRoute = ({ children }) => {
    const user = useSelector((state) => state.auth.user);

    return user && user.user_type === 'job_seeker' ? <div>
        <Nav />
        <div style={{ height: '100px' }}></div>
        {children}
        <div style={{ height: '100px' }}></div>
        <Footer />
    </div> : <Navigate to="/login" />
};

export default JobSeekerRoute;