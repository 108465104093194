import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions } from '@mui/material';
import ReportIcon from '@mui/icons-material/Report';
import Transition from "../Transition";
import styles from './Job.module.scss';
import moment from 'moment';

const Job = (props) => {
    const navigate = useNavigate();
    const { job, applyButton, onApplyClick } = props;
    const [infoDialog, setInfoDialog] = useState(false);

    const onJobClick = (id) => {
        navigate(`/job/${id}`);
    }

    const goToHrBee = () => {
        setInfoDialog(false);
        window.open('https://www.hrbee.ai/auth/jobseeker', '_blank');
    }

    return (
        <div className={styles.JobWrapper} onClick={() => onJobClick(job.job_id)}>
            <img src={`https://api.hrbee.ai/images-files/${job.logo_url}`} alt="Company Logo" className={styles.CompanyJobLogo} />
            <div className={styles.Divider}></div>
            <div className={styles.JobDescriptionPositionWrapper}>
                <h6 className={styles.JobDescriptionPosition}>
                    {job.job_title}
                </h6>
            </div>
            <div className={styles.Divider}></div>
            <div className='d-flex justify-content-around'>
                <div className={styles.JobDescription}>
                    <p className={styles.JobDescriptionLabel}>
                        Qyteti
                    </p>
                    <h5 className={styles.JobDescriptionValue}>
                        {job.city_name}
                    </h5>
                </div>
                <div className={styles.JobDescription}>
                    <p className={styles.JobDescriptionLabel}>
                        Afati për aplikim
                    </p>
                    <h5 className={styles.JobDescriptionValue}>
                        {moment(job.deadline).format('DD/MM/YYYY')}
                    </h5>
                </div>
            </div>
            {applyButton && (
                <button className={styles.ApplyButton} onClick={() => onApplyClick(job.job_id)}>
                    Apliko
                </button>
            )}
            <Dialog
                open={infoDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setInfoDialog(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle align="center"><ReportIcon /></DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Për të aplikuar në këtë pozitë ju ftojmë të regjistroheni në HrBee.
                        </DialogContentText>
                    </DialogContent>
                <DialogActions>
                    <button className={styles.ApplyButtonModal} onClick={goToHrBee}>
                        Vazhdo
                    </button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Job;